.dashboard {
  .calendar-event {
    display: flex;
    margin-bottom: 0.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:before {
      content: ' ';
      display: block;
      width: 3px;
      border-radius: 1px;
      background: #212529;
      margin-right: 1rem;
    }
  }

  .calendar-day {
    font-size: 0.8rem;
  }
}
