@import './styles/variables';
@import '~bootstrap/scss/bootstrap';

input[type='time']::-webkit-calendar-picker-indicator {
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
}

.card {
  box-shadow: 0 0 0.25rem rgba(73, 80, 87, 0.05);
}

.form-floating > textarea.form-control {
  height: 100px;
}

.btn-back {
  box-shadow: none !important;
}

.section {
  margin-bottom: 2rem;
}
