// $dark: #264653;
// $primary: #2a9d8f;
// $secondary: #e9c46a;
// $white: #fff;

// $body-bg: $dark;
// $body-color: $white;

// $input-bg: $dark;
// $form-label-color: $primary;
// $input-border-color: $primary;
// $form-select-indicator-color: $primary;

// $card-bg: transparent;
// $card-border-color: $primary;

// AFTER REFACTOR

$font-family-sans-serif: 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$primary: #212529;

$body-color: #343a40;
$headings-font-weight: 400;
$card-border-width: 0;

$card-border-radius: 0.5rem;
