.panel-content {
  background: #f3f4f7;
}

.panel-title {
  font-size: 2rem;
}

.header-logo {
  width: 100px;
}

.panel-menu {
  box-shadow: 0 0 0.25rem rgba(73, 80, 87, 0.05);
}

@media (max-width: 991.9px) {
  .panel-content.vh-100 {
    margin-top: 72px;
    height: calc(100dvh - 72px - env(safe-area-inset-bottom)) !important;
  }
}
